import React, { createContext, useState, useContext } from "react";
import { ClerkProvider } from "@clerk/clerk-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider } from "antd";

import logo from "../assets/logo.svg";

export const AppContext = createContext();

const queryClient = new QueryClient();

const PUBLISHABLE_KEY = process.env.GATSBY_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

export const AppProvider = ({ children }) => {
  const [savingStatus, setSavingStatus] = useState(null);

  const setSavingStatusHandler = (status) => {
    setSavingStatus(status);
  };

  console.log({ PUBLISHABLE_KEY });

  return (
    <ClerkProvider
      appearance={{
        layout: {
          // helpPageUrl: "https://clerk.com/docs/react/customization",
          logoImageUrl: logo,

          // privacyPageUrl: "https://clerk.com/docs/react/customization",
          // termsPageUrl: "https://clerk.com/docs/react/customization",
          // socialButtonsPlacement: "bottom",
          // socialButtonsVariant: "iconButton",
        },
        variables: {
          colorPrimary: "#F3614C",
          colorDanger: "#dc3545",
          colorSuccess: "#28a745",
          colorNeutral: "#000000",
          colorTextOnPrimaryBackground: "#ffffff",
          colorTextSecondary: "#616161",
          // colorBackground: "#f7f7f7",
          borderRadius: "2px",
          // fontSize: "16px",
        },
      }}
      publishableKey={PUBLISHABLE_KEY}
      afterSignOutUrl="/login"
      signInForceRedirectUrl="/app"
      signUpForceRedirectUrl="/app"
    >
      <AppContext.Provider
        value={{
          savingStatus,
          setSavingStatusHandler,
        }}
      >
        <ConfigProvider
          theme={{
            components: {
              colorPrimary: "#F3614C",
              Button: {
                // borderRadius: "24px",
                colorPrimary: "#F3614C",
                // defaultActiveColor: "#ffffff",
                // contentFontSize: 16,
                // colorTextBase: "#ffffff",
                algorithm: true,
              },
              Card: {
                borderRadiusLG: "28px",
              },
              Segmented: {
                trackPadding: 4,
                borderRadius: "2px",
                // trackBg: "#FFF4BD",
                // itemSelectedBg: "#F3614C",
                // itemSelectedColor: "#FFF",
              },
              Radio: {
                colorPrimary: "#F3614C",
                buttonCheckedBg: "#F3614C",
                colorPrimaryActive: "#F3614C",
                colorPrimaryHover: "#F3614C",
              },
              Switch: {
                colorPrimary: "#F3614C",
                colorPrimaryHover: "#F3614C",
              },
              Checkbox: {
                colorPrimary: "#F3614C",
                colorPrimaryHover: "#F3614C",
              },
            },
          }}
        >
          <QueryClientProvider client={queryClient}>
            {children}
          </QueryClientProvider>
        </ConfigProvider>
        {/* {console.log("hh", { PUBLISHABLE_KEY })} */}
      </AppContext.Provider>
    </ClerkProvider>
  );
};

export const useAppContext = () => useContext(AppContext);
