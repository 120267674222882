// // const React = require("react");
// // const { ClerkProvider } = require("@clerk/clerk-react");
// // const PUBLISHABLE_KEY = process.env.CLERK_PUBLISHABLE_KEY;

// // if (!PUBLISHABLE_KEY) {
// //   throw new Error("Missing Publishable Key");
// // }

// // exports.wrapRootElement = ({ element }) => {
// //   return (
// //     <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
// //       {element}
// //     </ClerkProvider>
// //   );
// // };

import React from "react";
import RootElement from "./src/components/root-element";

export const wrapPageElement = ({ element }) => {
  return <RootElement>{element}</RootElement>;
};
